import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { FC } from "react"
import { transformImage } from "../../../../../../gatsby/transformers/imageTransformer"
import ImageType from "../../../../../model/ImageType"
import GImage from "../../../../../../gatsby/GImage"
import { Swiper, SwiperSlide } from "swiper/react"
import Carousel from "../../../../carousel/Carousel"

const Container = styled.div`
  justify-content: space-between;
  align-items: center;
  background-color: white;
  width: 100%;
  max-width: 1200px;
  padding: 10px;

  .swiper-wrapper {
    justify-content: space-between;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
  }
`
const Image = styled(GImage)`
  height: 100px;
  object-fit: contain;
  display: block;
  min-width: 0;
  margin: 10px 30px;
`
const Item = styled.div``

interface TrustedByType {
  url?: string
  image: ImageType
}

const BREAK_POINTS = {
  320: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 2,
    spaceBetween: 50,
  },
  956: {
    slidesPerView: 4,
    spaceBetween: 50,
  },
}

interface Props {}

const LPTrustedBy: FC<Props> = () => {
  // transformImage(gatsbyProduct.image, gatsbyProduct.name)
  // const trustedByNodes = useStaticQuery(queryString)
  const trustedByNodes = useStaticQuery(queryString).allWp.nodes[0].options.custom.trustedBy
  const trustedByItems: TrustedByType[] = trustedByNodes.map(node => ({
    url: node.url,
    image: transformImage(node.logo, node.name),
  }))

  return (
    <Container>
      <Carousel
        swiperProps={{
          breakpoints: BREAK_POINTS,
          allowTouchMove: false,
          style: { justifyContent: "space-between" },
          autoplay: { delay: 1000 },
          loop: true,
          pagination: false,
        }}
      >
        {trustedByItems.map(item => (
          <SwiperSlide key={item.image.src} style={{ marginRight: 0 }}>
            <Image image={item.image} />
          </SwiperSlide>
        ))}
      </Carousel>
    </Container>
  )
}

export default LPTrustedBy

// const queryString = graphql`
//   {
//     infoMenu: allWpMenu(filter: { slug: { eq: "info" } }) {
//       nodes {
//         menuItems {
//           nodes {
//             id
//             label
//             url
//           }
//         }
//       }
//     }
//   }
// `
const queryString = graphql`
  {
    allWp {
      nodes {
        options {
          custom {
            trustedBy {
              logo {
                srcSet
                sourceUrl
                sizes
                databaseId
              }
              url
            }
          }
        }
      }
    }
  }
`
