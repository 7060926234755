import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import ReviewsIoTextBanner from "../../../../../../components/home/reviews-io/ReviewsIoTextBanner"
import { grey } from "../../../../../constants/colors"
import LPTrustedBy from "./LPTrustedBy"
import { minionFont } from "../../../../../constants/fonts"

const Container = styled.div`
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : grey)};
  padding: 40px;
  padding-bottom: 80px;
`
const Body = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
`
const Title = styled.h2`
  font-family: ${minionFont};
  text-transform: none;
  padding-left: 60px;
  margin-bottom: 40px;
  margin-top: 0;
`

interface Props {
  backgroundColor?: string
}

const LPReviewsAndTrustBlock: FC<Props> = ({ backgroundColor }) => {
  return (
    <Container backgroundColor={backgroundColor}>
      <Body>
        <ReviewsIoTextBanner />
        <Title>We're trusted by</Title>
        <LPTrustedBy />
      </Body>
    </Container>
  )
}

export default LPReviewsAndTrustBlock
