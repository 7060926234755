import * as React from "react"
import { FC, useState } from "react"
import styled from "styled-components"
import LPBanner1 from "../components/banner/LPBanner1"
import LPSellingPointImages, {
  ImageSellingPointType,
} from "../components/selling-points/LPSellingPointImages"
import LPReviewsAndTrustBlock from "../components/reviews/LPReviewsAndTrustBlock"
import { IconSellingPointType } from "../components/selling-points/LPIconSellingPointsBlock"
import LinkButton from "../../../button/LinkButton"
import LPCorporateQuotesBlock from "../components/reviews/LPCorporateQuotesBlock"
import LPPrideBlock from "../components/selling-points/LPPrideBlock"
import LPBrochureCTABlock from "../components/cta/LPBrochureCTABlock"
import LPVideoBlock from "../components/video/LPVideoBlock"
import LPBookCallCTABlock from "../components/cta/LPBookCallCTABlock"
import LPWhereNextCTABlock from "../components/cta/LPWhereNextCTABlock"
import LPEnquiryFormBlock from "../components/enquiry-form/LPEnquiryFormBlock"
import LPHamperFaqAccordionBlock from "../components/faq/LPHamperFaqAccordionBlock"
import EnquiryFormModal from "../components/lead-capture/enquiry-form/EnquiryFormModal"
import { getRegistrationLink } from "../../../../util/generalUtil"

const Container = styled.div``

const sellingPoints: ImageSellingPointType[] = [
  {
    image: {
      src: "https://data.socialsupermarket.org/wp-content/uploads/2018/10/Greattastelowres.gif",
      alt: "",
    },
    label: "Award-winning, delicious food & drink curated for every taste and budget",
  },
  {
    image: {
      src: "https://data.socialsupermarket.org/wp-content/uploads/2018/10/SEUKBCorp_Corporate-Landing-Page-Landscape-Image-Sizer-for-GIFs-etc-copy.png",
      alt: "",
    },
    label: "Sourced from pioneering UK-based social enterprises & B Corps",
  },
  {
    image: {
      src: "https://data.socialsupermarket.org/wp-content/uploads/2018/10/Screenshot-2022-09-27-at-19.15.19.jpg",
      alt: "",
    },
    label: "See & communicate the social impact each carbon neutral gift creates",
  },
]
const howYourGiftsArrive: ImageSellingPointType[] = [
  {
    image: {
      src: "https://data.socialsupermarket.org/wp-content/uploads/2018/10/HowYourGiftsArrive-09.jpg",
      alt: "",
    },
    label:
      "Choose from a gift in a wicker basket or a recycled gift box. Delivery is made using carbon neutral DPD Next Day Delivery.",
  },
  {
    image: {
      src: "https://data.socialsupermarket.org/wp-content/uploads/2018/10/HowYourGiftsArrive-11.jpg",
      alt: "",
    },
    label:
      "Alternatively choose a letterbox gift and your recipients will have their gifts waiting for them even if they're out.",
  },
  {
    image: {
      src: "https://data.socialsupermarket.org/wp-content/uploads/2018/10/HowYourGiftsArrive-10.jpg",
      alt: "",
    },
    label:
      "Add a gift note from your CEO or team leader, which can be printed up to 240 characters.",
  },
]

const howItWorkItems: IconSellingPointType[] = [
  {
    icon: "tss-multiple-gifts",
    // icon: "mail",
    description: (
      <p>
        <a target="_blank" href={getRegistrationLink()}>
          Register for your free account
        </a>{" "}
        to view all gift options.
      </p>
    ),
  },
  {
    icon: "tss-gift-selected",
    description:
      "Select your gift and upload an address spreadsheet. Or let recipients choose their own gift.",
  },
  {
    icon: "tss-track-impact",
    description: "See when your gifts have been delivered and download an impact report.",
  },
]

interface Props {}

const PwcLandingPage: FC<Props> = () => {
  const [showModal, setShowModal] = useState<boolean>(false)

  const contactItems: IconSellingPointType[] = [
    {
      icon: "tss-email",
      description: (
        <p>
          <LinkButton onClick={() => setShowModal(!showModal)}>Send us a quick email</LinkButton>{" "}
          and we will get back to you within 1-2 working days.
        </p>
      ),
    },
    {
      icon: "tss-call",
      description: (
        <p>
          <a
            target="_blank"
            href="https://meetings.hubspot.com/barney-close?uuid=415ede49-cbc5-4e27-81e7-eef4f3ddd62c"
          >
            Book a call
          </a>{" "}
          at a specific time
        </p>
      ),
    },
  ]

  //https://meetings.hubspot.com/barney-close?uuid=415ede49-cbc5-4e27-81e7-eef4f3ddd62c

  return (
    <div>
      <LPBanner1
        image={{
          src: "https://data.socialsupermarket.org/wp-content/uploads/2018/10/220801-Social-Supermarket-Christmas_Big-Boy-Hamper_VeerleEvens_050-WEB-1.jpg",
          alt: "background",
        }}
        heading="Send ethical festive gifts that do good"
        subHeading="PWC Hampers & Gifts Boxes"
        action="Contact Us"
        onAction={() => setShowModal(true)}
      />
      <LPSellingPointImages sellingPoints={sellingPoints} />

      <LPPrideBlock />
      <LPEnquiryFormBlock source={"Inbound Enquiry - General"} name={"GeneralEnquiryForm"} />
      <LPBrochureCTABlock
        subheading={"LOOKING FOR INSPARATION?"}
        title={"Let our brochure be your guide"}
        description={
          "View our Christmas hamper range here now with key statistics to help you visualise the impact you can create this festive season."
        }
        buttonLabel={"View our 2022 Gift Guide"}
        buttonTo={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/Social-Supermarket-2022-Christmas-Hamper-and-Gifting-Guide.pdf"
        }
        imageSrc={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/image-mockup-brochure-e1685530557408.png"
        }
      />
      <LPVideoBlock />
      {/*<ImpactProducts christmasImpactProducts={christmasImpactProducts} />*/}
      <LPReviewsAndTrustBlock />
      <LPCorporateQuotesBlock />
      <LPSellingPointImages sellingPoints={howYourGiftsArrive} title={"How your gifts arrive"} />
      <LPBookCallCTABlock
        description={
          <p>
            Speak to one of our team and we would be pleased to get you set up or answer any
            questions you may have. Alternatively, why not drop us a note through our{" "}
            <LinkButton onClick={() => setShowModal(!showModal)}>enquiry form</LinkButton>.
          </p>
        }
      />

      <LPHamperFaqAccordionBlock openEnquiryForm={() => setShowModal(!showModal)} />
      <LPWhereNextCTABlock />
      <EnquiryFormModal
        toggle={setShowModal}
        show={showModal}
        source={"Inbound Enquiry - General"}
        name={"GeneralEnquiryForm"}
      />
    </div>
  )
}

export default PwcLandingPage
