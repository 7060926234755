import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import InnerBlock from "../../../InnerBlock"
import Title from "../../../Title"
import Description from "../../../Description"
import Block from "../../../Block"
import { bigDesktop, desktop, smallMobile } from "../../../../../constants/screenSizes"
import useIntersectionObserver from "../../../../../hooks/useIntersectionObserver"
import { grey } from "../../../../../constants/colors"

const Container = styled.div`
  background-color: ${grey};
`

const VideoGrid = styled.div`
  display: grid;
  grid-template-columns: 630px 1fr;
  column-gap: 40px;
  padding: 50px 0;

  @media (max-width: ${bigDesktop}px) {
    column-gap: 20px;
  }
  @media (max-width: ${desktop}px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 0 0;
  }
  @media (max-width: ${smallMobile}px) {
    padding: 0;
  }
`
const VideoWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
`

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const VideoDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${desktop}px) {
    margin-top: 30px;
  }
  @media (max-width: ${smallMobile}px) {
    margin-top: 20px;
  }
`

interface Props {}

const LPVideoBlock: FC<Props> = () => {
  const videoContainerRef = React.useRef<HTMLDivElement>(null)
  const [videoUrl, setVideoUrl] = React.useState<string>(
    "https://www.youtube.com/embed/pkHkhbVXk5E?autoplay=0"
  )

  const onVideoEnterViewport = () => {
    setVideoUrl("https://www.youtube.com/embed/pkHkhbVXk5E?autoplay=1&mute=1")
  }

  const onVideoLeaveViewport = () => {
    setVideoUrl("https://www.youtube.com/embed/pkHkhbVXk5E?autoplay=0")
  }

  useIntersectionObserver(
    videoContainerRef,
    "",
    1,
    onVideoEnterViewport,
    onVideoLeaveViewport,
    false
  )

  return (
    <Container>
      <Block>
        <InnerBlock padding="0 65px 0 0">
          <VideoGrid>
            <VideoWrapper ref={videoContainerRef}>
              <StyledIframe src={videoUrl} allow="autoplay; encrypted-media" frameBorder="0" />
            </VideoWrapper>
            <VideoDescription>
              <Title>Sustainability is at the heart of our Christmas gifts.</Title>
              <Description margin="10px 0 40px 0">
                We use majority recycled materials and fully dissolvable fill in packaging your
                gifts.
              </Description>
            </VideoDescription>
          </VideoGrid>
        </InnerBlock>
      </Block>
    </Container>
  )
}

export default LPVideoBlock
