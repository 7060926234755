import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import Subheading from "../../../Subheading"
import { lightBlack, skyBlue } from "../../../../../constants/colors"
import EnquiryForm from "../lead-capture/enquiry-form/EnquiryForm"

const Container = styled.div<{ backgroundColor: string }>`
  padding: 40px 0;
  padding-bottom: 80px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`

const Body = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
`

const Title = styled(Subheading)`
  margin-top: 40px !important;
  margin-bottom: 40px;
`

interface Props {
  source: "Inbound Enquiry" | "Inbound Enquiry - Gifting" | "Inbound Enquiry - General"
  name: string
}

const LPEnquiryFormBlock: FC<Props> = ({ source, name }) => {
  return (
    <Container>
      <Body>
        <Title color={lightBlack} isBordered borderColor={skyBlue}>
          Get In Touch
        </Title>
        <EnquiryForm source={source} name={name} />
      </Body>
    </Container>
  )
}

export default LPEnquiryFormBlock
