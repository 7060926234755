import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import ImageType from "../../../../../model/ImageType"
import GImage from "../../../../../../gatsby/GImage"
import Subheading from "../../../Subheading"
import { lightBlack, skyBlue } from "../../../../../constants/colors"
import { largeDesktop, mobile, smallMobile, tablet } from "../../../../../constants/screenSizes"

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 40px 0;

  @media (max-width: ${largeDesktop}px) {
    padding: 40px 20px;
  }
`
const PointContainer = styled.div`
  width: calc(100% + 80px);
  margin-left: -40px;
  margin-right: -40px;
  display: flex;

  @media (max-width: ${largeDesktop}px) {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }

  @media (max-width: ${tablet}px) {
    font-size: 0.9em;
  }

  @media (max-width: ${mobile}px) {
    flex-direction: column;
    font-size: 1em;
    padding: 0 20px;
  }
`
const SellingPoint = styled.div`
  margin: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: ${largeDesktop}px) {
    margin: 20px;
  }

  @media (max-width: ${mobile}px) {
    max-width: 450px;
    margin: auto;
    margin-bottom: 30px;
  }
`
const Label = styled.div`
  margin-top: 10px;
`
const Title = styled(Subheading)`
  padding-top: 20px;
  margin-bottom: 10px;

  @media (max-width: ${mobile}px) {
    margin-bottom: 40px;
  }
`
const Image = styled(GImage)`
  object-fit: contain;
  flex-grow: 1;
`

export interface ImageSellingPointType {
  image: ImageType
  label: string
}

interface Props {
  sellingPoints: ImageSellingPointType[]
  title?: string
  subTitle?: string
}

const LPSellingPointImages: FC<Props> = ({ sellingPoints, title, subTitle }) => {
  return (
    <Container>
      {title && (
        <Title color={lightBlack} isBordered borderColor={skyBlue}>
          {title}
        </Title>
      )}
      <PointContainer>
        {sellingPoints.map(sellingPoint => (
          <SellingPoint>
            <Image image={sellingPoint.image} />
            <Label>{sellingPoint.label}</Label>
          </SellingPoint>
        ))}
      </PointContainer>
    </Container>
  )
}

export default LPSellingPointImages
