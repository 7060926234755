import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import Block from "../../../Block"
import InnerBlock from "../../../InnerBlock"
import Part from "../../../Part"
import Subheading from "../../../Subheading"
import { skyBlue, white } from "../../../../../constants/colors"
import LargeTitle from "../../../LargeTitle"
import ColorWrapper from "../../../ColorWrapper"
import BurstDesktop from "../../../../../../svg/block-burst-white-desktop.svg"
import { bigDesktop, desktop, smallMobile, tablet } from "../../../../../constants/screenSizes"
import BurstTablet from "../../../../../../svg/block-burst-white-tablet.svg"
import BurstMobile from "../../../../../../svg/block-burst-white-mobile.svg"
import Description from "../../../Description"
import GLink from "../../../../../../gatsby/GLink"
import { Icon } from "semantic-ui-react"

const Container = styled.div``

const WhereToNextPart = styled(Part)<{ width?: string; marginMobile?: string }>`
  width: 460px;

  @media (max-width: ${bigDesktop}px) {
    width: 430px;
  }
  @media (max-width: ${desktop}px) {
    width: 335px;
  }
  @media (max-width: ${tablet}px) {
    width: 310px;
    max-width: 48.5%;
  }
  @media (max-width: ${smallMobile}px) {
    width: 100%;
    max-width: unset;
    margin: ${({ marginMobile }) => marginMobile || 0};
  }
`

export const LinkIcon = styled(Icon)<{ color: string }>`
  color: ${({ color }) => color};
`

const BurstWrapper = styled(ColorWrapper)`
  background-image: url(${BurstDesktop});
  background-color: ${skyBlue};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;

  @media (max-width: ${desktop}px) {
    background-image: url(${BurstTablet});
    background-size: cover;
  }
  @media (max-width: ${smallMobile}px) {
    background-image: url(${BurstMobile});
  }
`
const WhereToNextDescription = styled(Description)`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.71px;
`
const GoToLink = styled(GLink)<{ margin?: string; iconMargin?: string }>`
  margin: ${({ margin }) => margin || 0};
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 20px;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 1px;
  color: ${white};
  cursor: pointer;
  &:hover,
  &:visited {
    color: ${white};
    text-decoration: none;
  }
  ${LinkIcon} {
    margin: ${({ iconMargin }) => iconMargin || 0};
  }
`

interface Props {}

const LPWhereNextCTABlock: FC<Props> = () => {
  return (
    <BurstWrapper>
      <Block>
        <InnerBlock>
          <Part padding="0 0 28px 0" wrapPadding="0 0 12px 0" width="100%">
            <Subheading isBordered borderColor={white} color={white}>
              Where to next?
            </Subheading>
          </Part>
          <WhereToNextPart marginMobile="0 0 40px 0">
            <LargeTitle color={white}>Looking for something more bespoke?</LargeTitle>
            <WhereToNextDescription margin="15px 0 30px 0" color={white}>
              We also can tailor our ethical Christmas hampers and other gifts to a brief you
              supply.
            </WhereToNextDescription>
            <GoToLink iconMargin="0 10px 0 0" to="/bespoke-gifts">
              <LinkIcon name="arrow left" color={white} />
              Check out our bespoke options
            </GoToLink>
          </WhereToNextPart>
          <WhereToNextPart>
            <LargeTitle color={white}>Give your team a choice this christmas</LargeTitle>
            <WhereToNextDescription margin="15px 0 30px 0" color={white}>
              When you order using our Platform, you can let your team choose their gift from a
              selection you share.
            </WhereToNextDescription>
            <GoToLink iconMargin="0 0 0 10px" to="/team-gifting-platform">
              Find out more
              <LinkIcon name="arrow right" color={white} />
            </GoToLink>
          </WhereToNextPart>
        </InnerBlock>
      </Block>
    </BurstWrapper>
  )
}

export default LPWhereNextCTABlock
