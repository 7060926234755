import * as React from "react"
import PwcLandingPage from "../social-supermarket/components/landing-page/new/pages/PwcLandingPage"
import SEO from "../components/Seo"
import Layout from "../components/Layout"

const ChristmasHampersAndGiftBoxes = (): JSX.Element => {
  return (
    <Layout fullWidth>
      <SEO
        title="PWC Hampers & Gift Boxes"
        description="Sustainable & Ethical Corporate Hampers & Gift Boxes from the UK's best social enterprises."
      />
      <PwcLandingPage />
    </Layout>
  )
}

export default ChristmasHampersAndGiftBoxes
